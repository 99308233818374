

<template>
    <l-marker :key="marker.id" :lat-lng="marker.position">
        <!-- <l-icon ref="icon">
            <img class="makan-icon" :src="marker.imageUrl" />
        </l-icon> -->
        <!-- :icon-size="marker.iconSize == undefined ? [32, 32] : marker.iconSize"
            :icon-anchor="marker.iconAnchor == undefined ? [16, 16] : marker.iconAnchor"
            v-bind:icon-url="marker.iconUrl == undefined ? '/marker/map.png' : marker.iconUrl"
         -->
        <l-icon>
            <img width="50px" class="makan-icon" src="@/assets/images/marker/map.png" />
        </l-icon>
        <l-popup>
            <div @click="innerClick">
                <span class="title-icon">
                    {{ marker.station_name }}

                </span>
                <p>
                    {{ marker.kabupaten_name }} , {{ marker.propinsi_name }}, {{ marker.region_description }}
                </p>
            </div>
        </l-popup>
    </l-marker>
</template>
  
<script>
import {
    LIcon, LMarker, LPopup,
    LTooltip,
} from 'vue2-leaflet'
export default {
    components: { LIcon, LMarker, LPopup },
    props: {
        marker: {
            type: Object,
            required: true
        },
        DATA_STATION1: Object

    }
}
</script>
  
<style>
.makan-icon {
    height: 50px;
    width: auto;
}

.title-icon {
    font-weight: bold;
    font-size: 14px;
    color: crimson;
}
</style>