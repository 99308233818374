<template>
  <div>
    <!-- add panel card statistic -->
    <b-row dense>
        <b-col cols="3">
          <b-card
            text-variant="white"
            header-tag="header"
            footer-tag="footer"
            style="margin-bottom: 1rem;color:white;background-color:#8492B4;"
          >
            

           <!-- font-size 40 -->
            <b-card-text class="text-h1">
              Total ARG <br/>5
            </b-card-text>

            <b-card-actions>
              <b-btn text>
                Klik Detail
              </b-btn>
            </b-card-actions>
          </b-card>
        </b-col>
        <!-- b col card color primary -->
        <b-col cols="3">
          <b-card
            text-variant="white"
            header-tag="header"
            footer-tag="footer"
            style="margin-bottom: 1rem;color:white;background-color: #45957F;"
          >
            

           <b-card-text class="text-h1">
              Total Stasiun Pos Hujan <br/>138
            </b-card-text>

            <b-card-actions>
              <b-btn text>
                Klik Detail
              </b-btn>
            </b-card-actions>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card
            text-variant="white"
            header-tag="header"
            footer-tag="footer"
            style="margin-bottom: 1rem;color:white;background-color: #607BDA;"
          >
            

           <b-card-text class="text-h1">
              Total AWS <br/>2
            </b-card-text>

            <b-card-actions>
              <b-btn text>
                Klik Detail
              </b-btn>
            </b-card-actions>
          </b-card>
        </b-col>
        <b-col cols="3">
          <b-card
            text-variant="white"
            header-tag="header"
            footer-tag="footer"
            style="margin-bottom: 1rem;color:white;background-color: #E54547;"
          >
            

           <b-card-text class="text-h1">
              Total QC Flag <br/>100
            </b-card-text>

            <b-card-actions>
              <b-btn text>
                Klik Detail
              </b-btn>
            </b-card-actions>
          </b-card>
        </b-col>
    </b-row>
    
    
    <b-row>
      <b-col>
        <b-card title="Maps">
          <!-- <l-map
					:zoom="zoom"
					:center="center"
					style="z-index:0"
					>
					<l-tile-layer :url="url" />
					<l-marker :lat-lng="markerLatLng" />
					<l-circle
						:lat-lng="circle.center"
						:radius="circle.radius"
						:color="circle.color"
					/>
					</l-map> -->

          <DinamicMaps @onClickMarker="onClickMarker" ref="dinamicMapComponent"></DinamicMaps>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col md="6" lg="6">
        <apex-data-bar-chart />
      </b-col>

      <b-col md="6" lg="6">
        <apex-donut-chart />
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BRow, BCol, BCard, BCardText, BLink, BListGroup, BListGroupItem, BCardTitle, BCardBody } from "bootstrap-vue";
import { LMap, LTileLayer, LMarker, LCircle } from "vue2-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import ApexDonutChart from "@/views/charts-and-maps/charts/apex-chart/ApexDonutChart.vue";
import ApexDataBarChart from "@/views/charts-and-maps/charts/apex-chart/ApexDataBarChart.vue";
import helper from "@/api/helper";
import useJwt from "@/auth/jwt/useJwt";
// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
/* eslint-disable global-require */

import DinamicMaps from "./../outputdata/visualisasi/DinamicMaps.vue";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    ApexDonutChart,
    ApexDataBarChart,
    DinamicMaps,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 4,
      center: [-2.7423878, 118.9871671],
      markerLatLng: [-2.7423878, 118.9871671],
      circle: {
        center: [-2.7423878, 118.98716714],
        radius: 4500,
        color: "#EA5455",
      },
      SET_MARKER: [-6.200001, 106.816666],
    };
  },
  mounted() {
    this.checkSession();
  },
  methods: {
    checkSession() {
      this.$refs.dinamicMapComponent.generateMarker(this.SET_MARKER);
      // helper.checkUser()
      // 	.then((response) => {
      // 		// console.log('response: ', response)

      // 	}).catch((error) => {
      // 		if (error.response.status == 401) {
      // 			this.$swal({
      // 				title: "Sesi anda telah habis.",
      // 				text: "Logout dan silahkan login kembali",
      // 				icon: "warning",
      // 				confirmButtonText: "OK",
      // 				allowOutsideClick: false,
      // 				allowEscapeKey: false,
      // 				customClass: {
      // 					confirmButton: "btn btn-primary",
      // 				},
      // 				buttonsStyling: false,
      // 			}).then((result) => {
      // 				localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      // 				localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // 				// Remove userData from localStorage
      // 				localStorage.removeItem('userData')

      // 				// Reset ability
      // 				// this.$ability.update(initialAbility)

      // 				// Redirect to login page
      // 				this.$router.push({ name: 'auth-login' })
      // 			});
      // 		}
      // 	});
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.vue2leaflet-map {
  &.leaflet-container {
    height: 350px;
  }
}
</style>
